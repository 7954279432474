import { useCallback, useEffect, useRef, useState } from "react";
import { OursageArticle, OursageArticlePage } from "../models";
import { useHttp } from "../hooks";
import { format } from "date-fns";
import {
	Box,
	Button,
	Modal,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { LightTooltip } from "../components";
import { useNavigate } from "react-router-dom";
import { useAlertContext, useUserContext } from "../contexts";

const pageSize = 20;

export const OursageArticlesPage = () => {
	const navigate = useNavigate();
	const oursageUrl = `${process.env.REACT_APP_CORNERSTONE_URL}oursage/`;
	const getUrl = `${oursageUrl}articles`;
	const queryString = (page: number) =>
		`enabledOnly=false&count=${pageSize}&offset=${(page - 1) * pageSize}`;
	const [pageNumber, setPageNumber] = useState(1);

	const { data, isLoading, isError, fetchData } = useHttp<OursageArticlePage>(
		getUrl,
		{
			method: "GET",
			queryString: queryString(pageNumber),
			autoFetch: true, // Automatically fetch on mount
		},
	);
	const [isRefreshRequired, setIsRefreshRequired] = useState(false);
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const deleteArticle = useRef<OursageArticle | null>(null);
	const { showAlert, clearAlert } = useAlertContext();
	const { user } = useUserContext();
	const hasEditPermission =
		user?.roles.includes("admin") || user?.roles.includes("marketing") || false;

	const retrieveArticles = useCallback(async () => {
		if (isRefreshRequired) {
			await fetchData();
			setIsRefreshRequired(false);
		}
	}, [isRefreshRequired, fetchData]);

	useEffect(() => {
		retrieveArticles();
	}, [retrieveArticles]);

	useEffect(() => {
		isError
			? showAlert({
					message: "Error retrieving articles",
					alertType: "error",
				})
			: clearAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	useEffect(() => {
		!hasEditPermission
			? showAlert({
					message: "You do not have permission to update articles",
					alertType: "info",
				})
			: clearAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasEditPermission]);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		page: number,
	) => {
		setPageNumber(page);
	};
	const handleEditClick = useCallback(
		(article: OursageArticle) => {
			if (!hasEditPermission) {
				return;
			}
			navigate("/oursageArticleEdit", { state: { article } });
		},
		[hasEditPermission, navigate],
	);

	const handleDeleteClick = useCallback(
		(article: OursageArticle) => {
			if (!hasEditPermission) {
				return;
			}
			deleteArticle.current = article;
			setDisplayDeleteModal(true);
		},
		[hasEditPermission],
	);

	const handleDeleteResponse = useCallback(
		async (response: boolean) => {
			if (response) {
				try {
					const response = await fetch(
						`${oursageUrl}article/${deleteArticle.current?.id}`,
						{
							method: "DELETE",
						},
					);

					if (response.ok) {
						setIsRefreshRequired(true);
					} else {
						throw new Error("Delete article failed");
					}
				} catch (e) {
					console.info("Delete article failed", e);
				}
			}
			setDisplayDeleteModal(false);
			deleteArticle.current = null;
		},
		[oursageUrl],
	);

	const renderTableRow = (article: OursageArticle) => {
		return (
			<TableRow
				key={article.id}
				sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
			>
				<TableCell component="th" scope="row">
					{article.title}
				</TableCell>
				<TableCell align="left" style={{ display: "flex" }}>
					{article.imageUrl}
					<img
						src={article.imageUrl}
						alt={article.title}
						style={{ maxWidth: 180 }}
					/>
				</TableCell>
				<TableCell align="left">
					<div style={{ display: "flex", gap: 10 }}>
						{article.articleUrl}
						<a
							href={article.articleUrl}
							target="_blank"
							rel="noreferrer"
							style={{ color: "#1565c0" }}
						>
							<OpenInNewIcon />
						</a>
					</div>
				</TableCell>
				<TableCell align="center">
					{article.enabled && <CheckOutlinedIcon style={{ color: "green" }} />}
					{!article.enabled && <CloseOutlinedIcon style={{ color: "red" }} />}
				</TableCell>
				<TableCell align="center">{article.displayOrder}</TableCell>
				<TableCell align="left">
					{format(article.updated, "dd/MM/yyyy HH:mm")}
				</TableCell>
				<TableCell align="left">{renderTooltips(article)}</TableCell>
			</TableRow>
		);
	};

	const renderTooltips = (article: OursageArticle) => {
		return (
			<>
				{hasEditPermission ? (
					<LightTooltip title="Delete">
						<DeleteOutlineIcon
							className="icon"
							style={{ marginRight: 10 }}
							onClick={() => {
								handleDeleteClick(article);
							}}
						/>
					</LightTooltip>
				) : (
					<DeleteOutlineIcon
						className="icon-disabled"
						style={{ marginRight: 10 }}
					/>
				)}

				{hasEditPermission ? (
					<LightTooltip title="Edit">
						<EditOutlinedIcon
							className="icon"
							onClick={() => {
								handleEditClick(article);
							}}
						/>
					</LightTooltip>
				) : (
					<EditOutlinedIcon
						className="icon-disabled"
						onClick={() => {
							handleEditClick(article);
						}}
					/>
				)}
			</>
		);
	};

	const renderDeleteConfirmationModal = () => {
		return (
			<Modal
				open={displayDeleteModal}
				onClose={() => handleDeleteResponse(false)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box className="modal" sx={{ width: 150 }}>
					<h2 id="parent-modal-title">
						Delete '{deleteArticle.current?.title || ""}'
					</h2>
					<p id="parent-modal-description">
						Are you sure you want to delete article?
					</p>
					<div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
						<Button
							variant="outlined"
							onClick={() => handleDeleteResponse(false)}
						>
							No
						</Button>
						<Button
							variant="contained"
							onClick={() => handleDeleteResponse(true)}
						>
							Yes
						</Button>
					</div>
				</Box>
			</Modal>
		);
	};

	if (isLoading) {
		return (
			<Box className="icon-loading">
				<CircularProgress size={70} />
			</Box>
		);
	} else if (isError) {
		return <></>;
	} else {
		return (
			<>
				<h2>
					Our Sage articles
					{hasEditPermission ? (
						<LightTooltip title="Add">
							<AddCircleOutlineOutlinedIcon
								className="icon"
								style={{
									marginLeft: 20,
									fontSize: "1.1em",
									verticalAlign: "text-top",
								}}
								onClick={() => {
									handleEditClick({ id: 0 } as OursageArticle);
								}}
							/>
						</LightTooltip>
					) : (
						<AddCircleOutlineOutlinedIcon
							className="icon-disabled"
							style={{
								marginLeft: 20,
								fontSize: "1.1em",
								verticalAlign: "text-top",
							}}
						/>
					)}
				</h2>
				<TableContainer component={Paper}>
					<Table
						sx={{ minWidth: 650 }}
						aria-label="our sage artiles"
						className="striped-table"
					>
						<TableHead>
							<TableRow>
								<TableCell size="small">Title</TableCell>
								<TableCell size="small" align="left">
									Image
								</TableCell>
								<TableCell size="small" align="left">
									Article Url
								</TableCell>
								<TableCell size="small" align="left">
									Display in App
								</TableCell>
								<TableCell size="small" align="left">
									Display order
								</TableCell>
								<TableCell size="small" align="left">
									Updated
								</TableCell>
								<TableCell size="small" align="left" />
							</TableRow>
						</TableHead>
						{data?.articles && (
							<TableBody>
								{data?.articles.map((article) => renderTableRow(article))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				<Stack alignItems="center" style={{ marginTop: 10 }}>
					<Pagination
						shape="rounded"
						count={data?.totalPages || 0}
						page={pageNumber}
						onChange={handleChangePage}
					/>
				</Stack>
				{renderDeleteConfirmationModal()}
			</>
		);
	}
};
