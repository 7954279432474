import { createContext, useState, useContext } from "react";
import { User } from "../models";

type UserContextType = {
	user?: User;
	setUser: (value?: User) => void;
};

type UserContextProviderProps = {
	children: React.ReactNode;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: UserContextProviderProps) => {
	const [user, setUser] = useState<User | undefined>(undefined);
	// Use the following for local development to prevent redirect to msal login
	// const [user, setUser] = useState<User | undefined>({
	// 	name: "Andrew Roland",
	// 	username: "andrewroland@cornerstonehealth.com.au",
	// 	roles: ["admin"],
	// 	clinics: [],
	// });

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error("useUserContext must be used within a UserProvider");
	}
	return context;
};
