import { ReactNode } from "react";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Toolbar, Box, styled, Typography } from "@mui/material";

import { Header } from "./Header";
import { Drawer } from "./Drawer";
import { Footer } from "./Footer";
import { Main } from "./Main";

import { DrawerContextProvider } from "../contexts/DrawContext";
import { LoginButton } from "./LoginButton";

const OuterContainer = styled(Box)`
	display: flex;
	overflow: hidden;
	height: inherit;
	flex-direction: column;
	min-height: 100vh;
`;

const InnerContainer = styled(Box)`
	display: flex;
	flex: 1;
	overflow: hidden;
	height: inherit;
`;

const UnauthenticatedContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: inherit;
`;

interface ILayoutProps {
	children: NonNullable<ReactNode>;
}

export const Layout = ({ children }: ILayoutProps) => {
	return (
		<DrawerContextProvider>
			<AuthenticatedTemplate>
				<OuterContainer>
					<Header />
					<Toolbar />
					<InnerContainer>
						<Drawer />
						<Main>{children}</Main>
					</InnerContainer>
					<Footer />
				</OuterContainer>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<UnauthenticatedContainer>
					<Header />
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginTop: 100,
							alignItems: "center",
						}}
					>
						<Typography variant="h6" sx={{ marginBottom: 3 }}>
							You must login to access this site
						</Typography>
						<LoginButton />
					</div>
				</UnauthenticatedContainer>
			</UnauthenticatedTemplate>
		</DrawerContextProvider>
	);
};
