import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout, MsalListener, ProtectedRoute } from "./components";
import {
	DashboardPage,
	OursageArticlesPage,
	OursageArticleEdit,
	OursageLogsPage,
	ClinicSettingsPage,
	NoAccess,
} from "./pages";
import { ROUTES, ROUTE_ROLES } from "./constants";
import { useHttp } from "./hooks";
import { useAlertContext, useClinicContext, useUserContext } from "./contexts";
import { Clinic } from "./models";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export const App = () => {
	const clinicUrl = `${process.env.REACT_APP_CORNERSTONE_URL}clinic/`;
	const { data, isLoading, isError, fetchData } = useHttp<Clinic[]>(
		`${clinicUrl}all`,
		{
			method: "GET",
		},
	);
	const { clinics, setClinics } = useClinicContext();
	const { user, setUser } = useUserContext();
	const { showAlert } = useAlertContext();

	useEffect(() => {
		const fetchClinics = async () => {
			await fetchData();
		};
		if (user && !clinics) {
			fetchClinics();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, clinics]);

	useEffect(() => {
		if (data && !clinics) {
			setClinics(data);
			if (user?.roles.includes("admin")) {
				setUser({ ...user, clinics: data });
				return;
			}
			const userClinics = data.filter((clinic) =>
				user?.roles.includes(clinic.azureAdminRole),
			);
			if (userClinics.length > 0 && user) {
				setUser({ ...user, clinics: userClinics });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, data, clinics]);

	useEffect(() => {
		if (isError) {
			showAlert({ message: "Error fetching clinics", alertType: "error" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	if (isLoading) {
		return (
			<Box className="icon-loading">
				<CircularProgress size={70} />
			</Box>
		);
	} else {
		return (
			<>
				<MsalListener />
				<Layout>
					<Routes>
						<Route path="/" element={<Navigate to={ROUTES.main} />} />
						<Route path={ROUTES.main} element={<DashboardPage />} />
						<Route
							path={ROUTES.oursageArticles}
							element={
								<ProtectedRoute
									element={<OursageArticlesPage />}
									allowedRoles={ROUTE_ROLES[ROUTES.oursageArticles]}
									path={ROUTES.oursageArticles}
								/>
							}
						/>
						<Route
							path={ROUTES.oursageArticleEdit}
							element={
								<ProtectedRoute
									element={<OursageArticleEdit />}
									allowedRoles={ROUTE_ROLES[ROUTES.oursageArticleEdit]}
									path={ROUTES.oursageArticleEdit}
								/>
							}
						/>
						<Route
							path={ROUTES.oursageLogs}
							element={
								<ProtectedRoute
									element={<OursageLogsPage />}
									allowedRoles={ROUTE_ROLES[ROUTES.oursageLogs]}
									path={ROUTES.oursageLogs}
								/>
							}
						/>
						<Route
							path={ROUTES.clinicSettings}
							element={
								<ProtectedRoute
									element={<ClinicSettingsPage />}
									allowedRoles={ROUTE_ROLES[ROUTES.clinicSettings]}
									path={ROUTES.clinicSettings}
								/>
							}
						/>
						<Route path={ROUTES.noAccess} element={<NoAccess />} />
					</Routes>
				</Layout>
			</>
		);
	}
};
