import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";

interface option {
    label: string;
    value: any;
}

interface props {
  label?: string;
  value?: any;
  options?: option[];
  includeNone?: boolean;
  handleChange: Function;
  width: number
}
export const SelectControl = ({ label, value, options, includeNone, handleChange, width }: props) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    handleChange(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: width }}>
      <InputLabel id={`${label}-select-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={value}
        label={label}
        onChange={handleSelectChange}
      >
        {includeNone && (
          <MenuItem value="none"><em>None</em></MenuItem>
        )}
        {options?.map((option)=> (
            <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
