export const ROUTES = {
	main: "/dashboard",
	oursageArticles: "/oursageArticles",
	oursageArticleEdit: "/oursagearticleEdit",
	oursageLogs: "/oursageLogs",
	clinicSettings: "/clinicSettings",
	noAccess: "/noAccess",
};

// Example role-based access map
export const ROUTE_ROLES = {
	[ROUTES.oursageArticles]: ["admin", "marketing", "reader"],
	[ROUTES.oursageArticleEdit]: ["admin", "marketing"],
	[ROUTES.oursageLogs]: ["admin", "reader"],
	[ROUTES.clinicSettings]: ["admin", "reader"],
};
