import { Navigate } from "react-router-dom";
import { useUserContext } from "../contexts";
import { ROUTES } from "../constants";

interface ProtectedRouteProps {
	element: JSX.Element;
	allowedRoles: string[];
	path: string;
}

export const ProtectedRoute = ({
	element,
	allowedRoles,
	path,
}: ProtectedRouteProps) => {
	const { user } = useUserContext();
	let hasAccess = false;
	if (path === ROUTES.clinicSettings) {
		hasAccess =
			// For clinic settings, search for role starting with "centre_admin_" or "admin"
			hasAccess =
				user?.roles.some(
					(role) =>
						role.startsWith("centre_admin_") ||
						role === "admin" ||
						role === "reader",
				) || false;
	} else {
		// Check if the user has one of the allowed roles
		hasAccess =
			user?.roles.some((role) => allowedRoles.includes(role)) || false;
	}

	if (!hasAccess) {
		console.warn(
			`Access denied to path: ${path} for user roles: ${user?.roles}`,
		);
		return <Navigate to={`/NoAccess?page=${path}`} />;
	}

	return element;
};
