import { useEffect } from "react";
import { useAlertContext } from "../contexts";

export const DashboardPage = () => {
	const { clearAlert } = useAlertContext();
	useEffect(() => {
		clearAlert();
	});
	return <div>Dashboard</div>;
};
