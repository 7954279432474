import { useEffect } from "react";
import { useAlertContext } from "../contexts";
import { useSearchParams } from "react-router-dom";

const PathToMessage = new Map<string, string>([
	["/oursageArticles", "Our Sage articles"],
	["/oursagearticleEdit", "Our Sage articles"],
	["/oursageLogs", "Our Sage logs"],
	["/clinicSettings", "Clinic settings"],
]);

export const NoAccess = () => {
	const [searchParams] = useSearchParams();
	const { showAlert } = useAlertContext();

	useEffect(() => {
		const page = searchParams.get("page") || "";
		console.log("NoAccess useEffect page", page);
		const message = PathToMessage.get(page);
		console.log("NoAccess useEffect message", message);
		showAlert({
			message: `You do not have access ${message ? "to " + message : ""}`,
			alertType: "info",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	return <></>;
};
