import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";

import "./index.scss";
import { App } from "./App";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./constants";
import { AlertProvider, ClinicProvider, UserProvider } from "./contexts";

const container = document.getElementById("root");
const msalInstance = new PublicClientApplication(msalConfig as Configuration);

const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<CssBaseline />
		<BrowserRouter>
			<ClinicProvider>
				<UserProvider>
					<AlertProvider>
						<MsalProvider instance={msalInstance}>
							<App />
						</MsalProvider>
					</AlertProvider>
				</UserProvider>
			</ClinicProvider>
		</BrowserRouter>
	</React.StrictMode>,
);
