import { useAlertContext } from "../contexts/AlertContext";
import { Alert as AlertComponent } from "@mui/material";

const AlertMessage = () => {
	const { alert } = useAlertContext();

	if (!alert) return null;

	return (
		<AlertComponent id="alert" severity={alert.alertType}>
			{alert.message}
		</AlertComponent>
	);
};

export default AlertMessage;
