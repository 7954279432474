import {
  Dashboard as DashboardIcon,
  ArticleOutlined as ArticleIcon,
  TerrainOutlined as TerrainOutlinedIcon,
  ToggleOnOutlined as ToggleOnOutlinedIcon
} from "@mui/icons-material";

import { IMenuItem } from "../models";
import { ROUTES } from "./routes";

export const MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.main,
    literal: "Dashboard",
    Icon: DashboardIcon
  },
  {
    route: ROUTES.oursageArticles,
    literal: "Our Sage articles",
    Icon: ArticleIcon
  },
  {
    route: ROUTES.oursageLogs,
    literal: "Our Sage logs",
    Icon: TerrainOutlinedIcon
  },
  {
    route: ROUTES.clinicSettings,
    literal: "Clinic settings",
    Icon: ToggleOnOutlinedIcon
  }
];
