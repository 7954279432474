import { useCallback, useEffect, useRef, useState } from "react";
import { useHttp } from "../hooks";
import { OursageLog, OursageLogSearch, PageOursageLog } from "../models";
import {
	Box,
	Button,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import { SelectControl } from "../components";
import { useAlertContext } from "../contexts";

const pageSize = 20;
const initialOursageLogSearch = {
	patientId: undefined,
	product: undefined,
	function: undefined,
	source: undefined,
	suuccess: undefined,
	sessionId: undefined,
	fromDate: format(new Date(), "yyyy-MM-dd"),
	toDate: `${format(new Date(), "yyyy-MM-dd")}T23:59:59`,
	offset: 0,
	count: pageSize,
};

const products = [
	"1-2 day Carer's Certificate",
	"1-2 day Medical Certificate",
	"carer's certificate",
	"Consult",
	"medical certificate",
	"Priority consult ($65)",
	"Priority consult ($75)",
	"Priority consult ($72)",
	"Repeat prescription",
	"Standard telehealth consult ($45)",
];

const functions = [
	"calling API verify_patient",
	"cancel_checkin",
	"confirm_checkin",
	"load_payment",
	"re send verification code to email",
	"re send verification code to mobile",
	"send verification code to Email",
	"send verification code to mobile",
	"payment pre-authorised",
	"validate entered verification code",
];

const sources = ["mobile", "web"];

const successes = [
	{ label: "true", value: "true" },
	{ label: "false", value: "false" },
];

export const OursageLogsPage = () => {
	const oursageUrl = `${process.env.REACT_APP_CORNERSTONE_URL}oursage/`;
	const [body, setBody] = useState<OursageLogSearch>(initialOursageLogSearch);
	const [pageNumber, setPageNumber] = useState(1);
	const { data, isLoading, isError, fetchData } = useHttp<PageOursageLog>(
		`${oursageUrl}Search`,
		{
			method: "POST",
			body: body,
		},
	);
	const [filters, setFilters] = useState<OursageLogSearch>(body);
	const patientIdRef = useRef<HTMLInputElement>(null);
	const sessionIdRef = useRef<HTMLInputElement>(null);
	const { showAlert, clearAlert } = useAlertContext();

	const retrieveLogs = useCallback(
		async () => {
			if (
				!filters.patientId &&
				!filters.product &&
				!filters.function &&
				!filters.sessionId &&
				!filters.fromDate &&
				!filters.toDate
			) {
				showAlert({
					message:
						"At least one filter (PatientId, Product, Function, SessionId, FromDate, or ToDate) must be supplied.",
					alertType: "error",
				});
				return;
			}
			clearAlert();
			await fetchData();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetchData],
	);

	useEffect(() => {
		retrieveLogs();
	}, [retrieveLogs]);

	useEffect(() => {
		isError
			? showAlert({
					message: "Error retrieving logs",
					alertType: "error",
				})
			: clearAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	const handleNumber = (value: string): string => {
		return value.replace(/\D/g, "");
	};

	const handleFiltersChange = (e: any): void => {
		let { name, value } = e.target;
		if (name === "patientId") {
			value = handleNumber(value);
		} else if (name === "toDate" && value !== "") {
			value = `${value}T23:59:59`;
		}
		const newFilters = {
			...filters,
			[name]: value !== "" ? value : null,
			offset: 0,
		};
		setFilters(newFilters);
	};

	const handleTableCellClick = (id: string, value: any) => {
		switch (id) {
			case "sessionId":
				if (sessionIdRef.current) {
					sessionIdRef.current.focus();
				}
				break;
			case "patientId":
				if (patientIdRef.current) {
					patientIdRef.current.focus();
				}
				break;
			default:
				break;
		}
		const newFilters = {
			...filters,
			[id]: value,
			offset: 0,
		};
		setFilters(newFilters);
	};

	const handleSelectChange = (id: string, value: any) => {
		let convertedValue =
			value && value !== "none" && value !== "" ? value : null;
		if (id === "success" && convertedValue != null) {
			convertedValue = convertedValue === "true";
		}
		const newFilters = {
			...filters,
			[id]: convertedValue,
		};
		setFilters(newFilters);
	};

	const handleApplyClick = () => {
		setBody({ ...filters });
		const newPageNumber = Math.ceil(filters.offset / pageSize) + 1;
		if (newPageNumber !== pageNumber) {
			setPageNumber(newPageNumber);
		}
	};

	const handleChangePage = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			if (page === pageNumber) {
				return;
			}
			const offset = (page - 1) * pageSize;
			setBody((prevBody) => ({ ...prevBody, offset }));
			setPageNumber(page);
		},
		[pageNumber],
	);

	const renderFilters = () => {
		return (
			<Box
				component="section"
				sx={{ p: 2, border: "1px solid #1565c0", marginBottom: 4 }}
			>
				<div
					style={{
						position: "relative",
						marginTop: -39,
						marginLeft: -10,
						fontWeight: 500,
						color: "#1565c0",
						backgroundColor: "white",
						padding: 10,
						width: 53,
					}}
				>
					Filter
				</div>
				<Stack direction="row" spacing={3} sx={{ marginBottom: 4 }}>
					<TextField
						inputRef={patientIdRef}
						id="patientId"
						name="patientId"
						label="Patient Id"
						variant="standard"
						value={filters?.patientId?.toString()}
						size="small"
						onChange={handleFiltersChange}
						style={{ width: 120 }}
					/>
					<SelectControl
						label="Product"
						value={filters.product || ""}
						options={products.map((product) => ({
							value: product,
							label: product,
						}))}
						includeNone={true}
						handleChange={(value: string) =>
							handleSelectChange("product", value)
						}
						width={330}
					/>
					<SelectControl
						label="Function"
						value={filters.function || ""}
						options={functions.map((option) => ({
							value: option,
							label: option,
						}))}
						includeNone={true}
						handleChange={(value: string) =>
							handleSelectChange("function", value)
						}
						width={300}
					/>
					<SelectControl
						label="Source"
						value={filters.source || ""}
						options={sources.map((option) => ({
							value: option,
							label: option,
						}))}
						includeNone={true}
						handleChange={(value: string) =>
							handleSelectChange("source", value)
						}
						width={120}
					/>
				</Stack>
				<Stack direction="row" spacing={3}>
					<SelectControl
						label="Success"
						value={filters.success?.toString() || ""}
						options={successes}
						includeNone={true}
						handleChange={(value: boolean) =>
							handleSelectChange("success", value)
						}
						width={120}
					/>
					<TextField
						inputRef={sessionIdRef}
						id="sessionId"
						name="sessionId"
						label="Session Id"
						variant="standard"
						value={filters?.sessionId}
						size="small"
						onChange={handleFiltersChange}
						style={{ width: 330 }}
					/>
					<TextField
						type="date"
						id="fromDate"
						name="fromDate"
						label="From"
						variant="standard"
						value={filters?.fromDate}
						size="small"
						slotProps={{
							inputLabel: {
								shrink: true,
							},
						}}
						onChange={handleFiltersChange}
					/>
					<TextField
						type="date"
						id="toDate"
						name="toDate"
						label="To"
						variant="standard"
						value={filters?.toDate?.substring(0, 10)}
						size="small"
						slotProps={{
							inputLabel: {
								shrink: true,
							},
						}}
						onChange={handleFiltersChange}
					/>
					<Button
						variant="outlined"
						size="small"
						style={{
							paddingLeft: 24,
							paddingRight: 24,
							paddingTop: 0,
							paddingBottom: 0,
						}}
						onClick={handleApplyClick}
					>
						Apply
					</Button>
				</Stack>
			</Box>
		);
	};

	const renderTableRow = (log: OursageLog) => {
		return (
			<TableRow key={log.id}>
				<TableCell
					component="th"
					scope="row"
					align="left"
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						color: "#1565c0",
					}}
					onClick={() => {
						handleTableCellClick("patientId", log.patientId);
					}}
				>
					{log.patientId}
				</TableCell>
				<TableCell align="left">{log.product}</TableCell>
				<TableCell align="left">{log.paymentAmount}</TableCell>
				<TableCell align="left">{log.function}</TableCell>
				<TableCell align="left">{log.source}</TableCell>
				<TableCell align="left">{log.httpStatusCode}</TableCell>
				<TableCell align="left">{log.success?.toString()}</TableCell>
				<TableCell align="left">{log.exceptionMessage}</TableCell>
				<TableCell
					align="left"
					style={{
						cursor: "pointer",
						textDecoration: "underline",
						color: "#1565c0",
					}}
					onClick={() => {
						handleTableCellClick("sessionId", log.sessionId);
					}}
				>
					{log.sessionId}
				</TableCell>
				<TableCell align="left">
					{format(log.created, "dd/MM/yyyy HH:mm")}
				</TableCell>
			</TableRow>
		);
	};

	if (isLoading) {
		return (
			<Box className="icon-loading">
				<CircularProgress size={70} />
			</Box>
		);
	} else if (isError) {
		return <></>;
	} else {
		return (
			<>
				<h2>Our Sage logs</h2>
				{renderFilters()}
				<TableContainer component={Paper}>
					<Table
						sx={{ minWidth: 650 }}
						aria-label="our sage artiles"
						className="striped-table"
					>
						<colgroup>
							<col width="8%" />
							<col width="12%" />
							<col width="10%" />
							<col width="10%" />
							<col width="8%" />
							<col width="8%" />
							<col width="8%" />
							<col width="10%" />
							<col width="16%" />
							<col width="10%" />
						</colgroup>
						<TableHead>
							<TableRow>
								<TableCell size="small">Patient Id</TableCell>
								<TableCell size="small" align="left">
									Product
								</TableCell>
								<TableCell size="small" align="left">
									Payment Amount
								</TableCell>
								<TableCell size="small" align="left">
									Function
								</TableCell>
								<TableCell size="small" align="left">
									Source
								</TableCell>
								<TableCell size="small" align="left">
									Http Status Code
								</TableCell>
								<TableCell size="small" align="left">
									Success
								</TableCell>
								<TableCell size="small" align="left">
									Exception Message
								</TableCell>
								<TableCell size="small" align="left">
									SessionId
								</TableCell>
								<TableCell size="small" align="left">
									Created
								</TableCell>
							</TableRow>
						</TableHead>
						{data?.logs && (
							<TableBody>
								{data?.logs.map((log) => renderTableRow(log))}
							</TableBody>
						)}
					</Table>
				</TableContainer>
				<Stack alignItems="center" style={{ marginTop: 10 }}>
					<Pagination
						shape="rounded"
						count={data?.totalPages || 0}
						page={pageNumber}
						onChange={handleChangePage}
					/>
				</Stack>
			</>
		);
	}
};
