import { useEffect, useState } from "react";
import { OursageArticle } from "../models";
import { useHttp } from "../hooks";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlertContext } from "../contexts";

interface articleErrors {
	title: string;
	imageUrl: string;
	articleUrl: string;
	displayOrder: string;
}

export const OursageArticleEdit = () => {
	const location = useLocation();
	const article = location.state?.article as OursageArticle;
	const navigate = useNavigate();
	const oursageUrl = `${process.env.REACT_APP_CORNERSTONE_URL}oursage/`;
	const [editArticle, setEditArticle] = useState<OursageArticle | null>(
		article,
	);
	const [errors, setErrors] = useState<articleErrors>({} as articleErrors);
	const { isLoading, isError, fetchData } = useHttp<OursageArticle[]>(
		`${oursageUrl}article`,
		{
			method: article.id > 0 ? "PUT" : "POST",
			body: editArticle as Record<string, any>,
		},
	);
	const [returnButtonText, setReturnButtonText] = useState("cancel");
	const [dataHasBeenSaved, setDataHasBeenSaved] = useState(false);
	const { showAlert, clearAlert } = useAlertContext();

	useEffect(() => {
		isError
			? showAlert({
					message: "Error saving article",
					alertType: "error",
				})
			: clearAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError]);

	const handleChange = (e: any) => {
		setReturnButtonText("cancel");
		const value = e.target.id !== "enabled" ? e.target.value : e.target.checked;
		if (editArticle) {
			setEditArticle({
				...editArticle,
				[e.target.id]: value,
			});
		}
	};

	const validateArticle = (): boolean => {
		const newErrors = {} as any;

		if (!editArticle?.title) {
			newErrors.title = "Title is required";
		}

		if (!editArticle?.imageUrl) {
			newErrors.imageUrl = "Image url is required";
		}

		if (!editArticle?.articleUrl) {
			newErrors.articleUrl = "Article url is required";
		}

		if (!editArticle?.displayOrder) {
			newErrors.displayOrder = "Display order is required";
		} else if (editArticle.displayOrder <= 0) {
			newErrors.displayOrder = "Display order must be a number greater than 0";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSaveArticle = async (save: boolean) => {
		if (!save) {
			setEditArticle(null);
			setErrors({} as articleErrors);
			navigate("/oursageArticles");
			return;
		}
		if (!validateArticle()) {
			return;
		}
		fetchData();

		if (!isError) {
			setReturnButtonText("Return");
			setDataHasBeenSaved(true);
		}
	};

	if (isLoading) {
		return (
			<Box className="icon-loading">
				<CircularProgress size={70} />
			</Box>
		);
	} else if (isError) {
		return <></>;
	} else {
		return (
			<>
				<h2>Edit Our Sage article</h2>
				<div
					style={{
						backgroundColor: "#ffffff",
						opacity: 1,
						display: "flex",
						flexDirection: "column",
						gap: 20,
						marginTop: 20,
					}}
				>
					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<TextField
							id="title"
							label="Title"
							variant="standard"
							multiline
							size="small"
							value={editArticle?.title}
							onChange={(e) => handleChange(e)}
						/>
						{errors.title && <span className="error">{errors.title}</span>}
					</div>

					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<TextField
							id="imageUrl"
							label="Image url"
							variant="standard"
							multiline
							value={editArticle?.imageUrl}
							onChange={(e) => handleChange(e)}
						/>
						{errors.imageUrl && (
							<span className="error">{errors.imageUrl}</span>
						)}
						<img
							src={editArticle?.imageUrl}
							alt={editArticle?.imageUrl}
							style={{ maxWidth: 180 }}
						/>
					</div>

					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
							<TextField
								id="articleUrl"
								label="Article url"
								variant="standard"
								multiline
								value={editArticle?.articleUrl}
								onChange={(e) => handleChange(e)}
								style={{ flexGrow: 1 }}
							/>
							<a href={article.articleUrl} target="_blank" rel="noreferrer">
								<OpenInNewIcon />
							</a>
						</div>

						{errors.articleUrl && (
							<span className="error">{errors.articleUrl}</span>
						)}
					</div>

					<FormControlLabel
						control={<Checkbox id="enabled" checked={editArticle?.enabled} />}
						label="Display in App"
						onChange={(e) => handleChange(e)}
					/>

					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<TextField
							id="displayOrder"
							type="number"
							label="Display order"
							variant="standard"
							style={{ width: 100 }}
							value={editArticle?.displayOrder}
							onChange={(e) => handleChange(e)}
						/>
						{errors.displayOrder && (
							<span className="error">{errors.displayOrder}</span>
						)}
					</div>
					<div style={{ display: "flex", justifyContent: "flex-end", gap: 20 }}>
						{dataHasBeenSaved && (
							<div style={{ display: "flex", paddingTop: 6 }}>
								<CheckOutlinedIcon style={{ color: "green" }} />
								<h3
									style={{
										marginTop: 2,
										marginRight: 8,
										marginBottom: 2,
										marginLeft: 8,
									}}
								>
									Saved
								</h3>
							</div>
						)}
						<Button variant="outlined" onClick={() => handleSaveArticle(false)}>
							{returnButtonText}
						</Button>
						<Button variant="contained" onClick={() => handleSaveArticle(true)}>
							Save
						</Button>
					</div>
				</div>
			</>
		);
	}
};
