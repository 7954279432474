import { PropsWithChildren } from "react";
import { styled } from "@mui/material";
import AlertMessage from "./AlertMessage";

const StyledMain = styled("main")`
	height: 100%;
	flex: 1;
	overflow: auto;
	padding: 10px;
	width: 100%;
`;

export const Main = ({ children }: PropsWithChildren<unknown>) => (
	<StyledMain>
		<AlertMessage />
		{children}
	</StyledMain>
);
