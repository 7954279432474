import { InteractionStatus } from "@azure/msal-browser";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from "@azure/msal-react";
import { Button } from "@mui/material";
import { loginRequest, msalConfig } from "../constants";

export const LoginButton = () => {
	const { instance, inProgress } = useMsal();

	const handleLogin = async () => {
		console.info(
			"LoginButton handleLogin msalConfig",
			JSON.stringify(msalConfig, null, 2),
		);
		console.info("LoginButton handleLogin loginRequest", loginRequest);
		console.info("LoginButton handleLogin inProgress", inProgress);
		if (inProgress === InteractionStatus.None) {
			try {
				await instance.loginRedirect(loginRequest);
			} catch (error) {
				console.info("LoginButton handleLogin loginRedirect error");
				console.error(error);
			}
		} else {
			console.log(
				"LoginButton handleLogin Another interaction is in progress.",
			);
		}
	};

	const handleLogout = () => {
		instance.logoutRedirect().catch((error) => console.log(error));
	};

	return (
		<>
			<UnauthenticatedTemplate>
				<Button
					variant="outlined"
					size="large"
					onClick={handleLogin}
					disabled={inProgress !== InteractionStatus.None}
				>
					{inProgress === InteractionStatus.None ? "Login" : "Logging in..."}
				</Button>
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<div className="collapse navbar-collapse justify-content-end">
					<Button
						size="small"
						sx={{ color: "secondary.contrastText" }}
						onClick={handleLogout}
					>
						Logout
					</Button>
				</div>
			</AuthenticatedTemplate>
		</>
	);
};
