import { Link } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { IMenuItem } from "../models";
import { useDrawerContext } from "../contexts";
import { LightTooltip } from "./Tooltips";

type Props = IMenuItem & {
	selected?: boolean;
	onClick?: () => void;
};

export const MenuItem: React.FC<Props> = ({
	route,
	literal,
	Icon,
	selected,
	onClick,
}) => {
	const { isOpened } = useDrawerContext();

	const renderIcon = (
		<ListItemIcon
			sx={[
				{ minWidth: "auto" },
				(theme) => ({
					paddingRight: theme.spacing(2),
				}),
			]}
		>
			<Icon sx={{ color: "primary.dark" }} />
		</ListItemIcon>
	);

	const link = (
		<ListItemButton
			selected={selected}
			sx={{
				paddingBottom: 3,
				"&.Mui-selected": {
					backgroundColor: "common.white",
					color: "primary.dark",
				},
				"&:hover": {
					backgroundColor: "common.white",
					color: "primary.dark",
				},
			}}
			onClick={onClick}
		>
			{isOpened ? (
				<>
					{renderIcon}
					<ListItemText primary={literal} />
				</>
			) : (
				<LightTooltip title={literal}>{renderIcon}</LightTooltip>
			)}
		</ListItemButton>
	);

	return route ? <Link to={route}>{link}</Link> : link;
};
