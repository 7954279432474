import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.REACT_APP_AZURE_CLIENTID || "", // From Azure App Registration
		authority: process.env.REACT_APP_AZURE_AUTHORITY || "",
		redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
		postLogoutRedirectUri: process.env.REACT_APP_AZURE_POST_LOGOUT_REDIRECT_URI,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: "sessionStorage", // "localStorage" is also valid
		storeAuthStateInCookie: true, // Set to true if issues arise with older browsers
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, piiLoggingEnabled) => {
				if (level === LogLevel.Error) {
					console.error(message);
				} else if (level === LogLevel.Warning) {
					console.warn(message);
				} else {
					console.log(message);
				}
			},
			logLevel: LogLevel.Error,
			piiLoggingEnabled: false,
		},
	},
};

export const loginRequest = {
	scopes: [`api://${process.env.REACT_APP_AZURE_CLIENTID}/User.Access`],
};
