import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
interface props {
	name: string;
	value: number | string;
	min?: number;
	width?: number;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export const InputNumber = ({
	name,
	value,
	min,
	width,
	handleChange,
}: props) => {
	return (
		<TextField
			type="number"
			value={value}
			name={name}
			onChange={handleChange}
			slotProps={{
				inputLabel: {
					shrink: true,
				},
				htmlInput: {
					min: min,
				},
			}}
			size="small"
			style={width ? { width } : undefined}
		/>
	);
};
