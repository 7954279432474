import { EventType, EventMessage, AccountInfo } from "@azure/msal-browser";
import { useUserContext } from "../contexts";
import { useMsal } from "@azure/msal-react";

export const MsalListener = () => {
	const { user, setUser } = useUserContext();
	const { instance } = useMsal();

	const parseJwt = (token: string) => {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
				.join(""),
		);
		return JSON.parse(jsonPayload);
	};

	// Default to using the first account if no account is active on page load
	if (instance.getAllAccounts().length > 0) {
		const account = instance.getAllAccounts()[0] as AccountInfo;
		if (!instance.getActiveAccount()) {
			instance.setActiveAccount(account);
		}
		if (!user || user.username !== account.username) {
			console.info(
				"MsalListener instance.getAllAccounts()",
				instance.getAllAccounts(),
			);
			const userRoles = parseJwt(account.idToken || "").roles;
			console.log(
				"MsalListener instance.getAllAccounts() User Roles:",
				userRoles,
			);
			console.log("MsalListener instance.getAllAccounts() calling setUser", {
				name: account.name || "",
				username: account.username || "",
				roles: userRoles || [],
			});
			setUser({
				name: account.name || "",
				username: account.username || "",
				roles: userRoles || [],
				clinics: [],
			});
		}
	}

	// Listen for sign-in event and set active account
	instance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload) {
			const account = (event.payload as any).account;
			if (!instance.getActiveAccount()) {
				instance.setActiveAccount(account as AccountInfo);
			}
			if (!user || user?.username !== account.username) {
				const userRoles = parseJwt(account.accessToken).roles;
				console.log(
					"MsalListener instance.addEventCallback User Roles:",
					userRoles,
				);
				console.log(
					"MsalListener instance.addEventCallback calling setUser",
					JSON.stringify(
						{
							name: account.name,
							username: account.userName,
							roles: userRoles || [],
						},
						null,
						2,
					),
				);
				setUser({
					name: account.name,
					username: account.userName,
					roles: userRoles || [],
					clinics: [],
				});
			}
		}
	});
	return <></>;
};
