import { createContext, useState, useContext } from "react";
import { Clinic } from "../models";

type ClinicContextType = {
	clinics?: Clinic[];
	setClinics: (value?: Clinic[]) => void;
};

type ClinicContextProviderProps = {
	children: React.ReactNode;
};

const ClinicContext = createContext<ClinicContextType | undefined>(undefined);

export const ClinicProvider = ({ children }: ClinicContextProviderProps) => {
	const [clinics, setClinics] = useState<Clinic[] | undefined>(undefined);

	return (
		<ClinicContext.Provider value={{ clinics, setClinics }}>
			{children}
		</ClinicContext.Provider>
	);
};

export const useClinicContext = () => {
	const context = useContext(ClinicContext);
	if (context === undefined) {
		throw new Error("useClinicContext must be used within a ClinicProvider");
	}
	return context;
};
