import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	useTheme,
} from "@mui/material";
import {
	Menu as MenuIcon,
	ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { useDrawerContext, useUserContext } from "../contexts";
import { LoginButton } from "./LoginButton";

export const Header = () => {
	const { isOpened, toggleIsOpened } = useDrawerContext();
	const { user } = useUserContext();
	const theme = useTheme();

	return (
		<AppBar
			sx={{ backgroundColor: "primary.dark", color: "secondary.contrastText" }}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					onClick={() => toggleIsOpened(!isOpened)}
					sx={{ padding: theme.spacing(1) }}
				>
					{isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
				</IconButton>
				<Typography variant="h6" sx={{ margin: "auto" }}>
					Cornerstone Admin
				</Typography>
				{user && (
					<>
						<div style={{ marginRight: 20 }}>{user.name}</div>
						<LoginButton />
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
